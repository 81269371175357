
import getEnvVars from './variables'
// const  { apiUrl }  = getEnvVars(process.env.REACT_APP_ENV);

// export const SERVER_URL = apiUrl.href
export const SERVER_URL = new URL('https://backend.lottomatic.io/')

// regular lotto
export const POST_REGULAR_LOTTO_FORM = new URL('/games/lotto/type/regular/0', SERVER_URL)
export const GET_REGULAR_LOTTO_FORM_PRICE = new URL('/games/lotto/type/regular/calculate_price', SERVER_URL)
export const POST_REGULAR_DOUBLE_LOTTO_FORM = new URL('/games/lotto/type/regular_double/0', SERVER_URL)
export const GET_REGULAR_DOUBLE_LOTTO_FORM_PRICE = new URL('/games/lotto/type/regular_double/calculate_price', SERVER_URL)

// lotto shitati
export const POST_LOTTO_SHITATI_FORM = new URL('/games/lotto/type/shitati/0', SERVER_URL)
export const GET_LOTTO_SHITATI_FORM_PRICE = new URL('/games/lotto/type/shitati/calculate_price', SERVER_URL)
export const POST_DOUBLE_LOTTO_SHITATI_FORM = new URL('/games/lotto/type/double_shitati/0', SERVER_URL)
export const GET_DOUBLE_LOTTO_SHITATI_FORM_PRICE = new URL('/games/lotto/type/double_shitati/calculate_price', SERVER_URL)

//lotto shitati hazak
export const POST_LOTTO_SHITATI_HAZAK_FORM = new URL('/games/lotto/type/shitati_hazak/0', SERVER_URL)
export const GET_LOTTO_SHITATI_HAZAK_FORM_PRICE = new URL('/games/lotto/type/shitati_hazak/calculate_price', SERVER_URL)
export const POST_DOUBLE_LOTTO_SHITATI_HAZAK_FORM = new URL('/games/lotto/type/double_shitati_hazak/0', SERVER_URL)
export const GET_DOUBLE_LOTTO_SHITATI_HAZAK_FORM_PRICE = new URL('/games/lotto/type/double_shitati_hazak/calculate_price', SERVER_URL)

// chance
export const POST_REGULAR_CHANCE_FORM = new URL('/games/chance/type/regular/0', SERVER_URL)
export const GET_REGULAR_CHANCE_FORM_PRICE = new URL('/games/chance/type/regular/calculate_price', SERVER_URL)
export const POST_RAV_CHANCE_FORM = new URL('/games/chance/type/rav/0', SERVER_URL)
export const GET_RAV_CHANCE_FORM_PRICE = new URL('/games/chance/type/rav/calculate_price', SERVER_URL)
export const POST_CHANCE_SHITATI_FORM = new URL('/games/chance/type/shitati/0', SERVER_URL)
export const GET_CHANCE_SHITATI_FORM_PRICE = new URL('/games/chance/type/shitati/calculate_price', SERVER_URL)

// 777
export const POST_REGULAR_777_FORM = new URL('/games/777/type/regular/0', SERVER_URL)
export const GET_REGULAR_777_FORM_PRICE = new URL('/games/777/type/regular/calculate_price', SERVER_URL)
export const POST_SHITATI_777_FORM = new URL('/games/777/type/shitati/0', SERVER_URL)
export const GET_SHITATI_777_FORM_PRICE = new URL('/games/777/type/shitati/calculate_price', SERVER_URL)

// 123
export const POST_REGULAR_123_FORM = new URL('/games/123/type/regular/0', SERVER_URL)
export const GET_REGULAR_123_FORM_PRICE = new URL('/games/123/type/regular/calculate_price', SERVER_URL)

// my space
export const GET_MY_SPACE_PULLINGS = new URL('/my_space/pullings', SERVER_URL)
export const GET_MY_SPACE_REFUNDS = new URL('/my_space/refunds', SERVER_URL)
export const GET_MY_SPACE_PAYMENT_HISTORY = new URL('/my_space/payment_history', SERVER_URL)
export const GET_MY_SPACE_ACTIVE_FORMS = new URL('/my_space/active_forms', SERVER_URL)
export const GET_MY_SPACE_FORMS_HISTORY = new URL('/my_space/forms_history', SERVER_URL)
export const GET_MY_SPACE_WINS = new URL('/my_space/wins', SERVER_URL)

// Admin
export const GET_PENDING_REFUNDS = new URL('/admin/pending_refunds', SERVER_URL)
export const GET_PENDING_FORMS = new URL('/admin/pending_forms', SERVER_URL)
export const GET_PRINTED_FORMS = new URL('/admin/printed_forms', SERVER_URL)
export const GET_SCANNED_FORMS = new URL('/admin/scanned_forms', SERVER_URL)
export const GET_WIN_FORMS = new URL('/admin/winners', SERVER_URL)
export const GET_IS_MANUAL = new URL('/admin/is_manual', SERVER_URL)
export const GET_ALL_FORMS = new URL('/admin/all_forms', SERVER_URL)
export const GET_ALL_FORMS_OWNERS = new URL('/admin/all_forms_owners', SERVER_URL)
export const GET_ALL_USERS = new URL('/admin/all_users', SERVER_URL)
export const GET_ALL_TRANSACTIONS = new URL('/admin/all_transactions', SERVER_URL)
export const GET_ALL_BATCHES = new URL('/admin/all_batches', SERVER_URL)
export const GET_FORM_TO_VALIDATE = new URL('/admin/validate_form', SERVER_URL)
export const SET_FORM_VALIDATE_STATE = new URL('/admin/validate_form', SERVER_URL)
export const SET_BATCH_INVALIDATE_STATE = new URL('/admin/invalidate_batch', SERVER_URL)
export const GET_ALL_BATCH = new URL('/admin/batch_list', SERVER_URL)

// results
export const GET_LOTTO_RESULTS_PATH = new URL('/results/lotto', SERVER_URL)
export const GET_EXTRA_RESULTS_PATH = new URL('/results/extra', SERVER_URL)
export const GET_CHANCE_RESULTS_PATH = new URL('/results/chance', SERVER_URL)
export const GET_777_RESULTS_PATH = new URL('/results/777', SERVER_URL)
export const GET_123_RESULTS_PATH = new URL('/results/123', SERVER_URL)


// user
export const GET_USER_BALANCE_PATH = new URL('/user/balance', SERVER_URL)

// payment
export const CHARGE_CREDIT_PATH = new URL('/payments/charge', SERVER_URL)
export const CHARGE_CREDIT_TOKEN_PATH = new URL('/payments/charge-token', SERVER_URL)
export const HAS_CREDIT_TOKEN_PATH = new URL('/payments/has_token', SERVER_URL)
export const REFUND_PATH = new URL('/payments/refund', SERVER_URL)
export const REFUND_TOKEN_PATH = new URL('/payments/refund-token', SERVER_URL)

// times
export const GET_TIMES_PATH = new URL('/games/time', SERVER_URL)


export const GET_TERMS_OF_USE_LINK = new URL('/terms', SERVER_URL)
export const GET_PRIVACY_LINK = new URL('/privacy-policy', SERVER_URL)


